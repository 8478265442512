body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none !important;
  box-sizing: border-box;
}

:root {
  --main-color: #114232;
  --secondary-color: #114232;
  --primary-color-hover: #86a789;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

button {
  background: transparent;
  border: none;
}

a {
  text-decoration: none;
}

.head-page {
  position: relative;
}

.head-page h1 {
  font-weight: bold;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  color: #fff;
  animation: fadeIn 2s 0s;
  -webkit-animation: fadeIn 2s 0s;
}

.head-page img {
  width: 100%;
  height: 50vh;
}

@media screen and (max-width: 600px) {
  .head-page h1 {
    font-size: 45px;
  }
}
