.services {
  padding-top: 40px;
  padding-bottom: 150px;
}

.services .trips-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.services .trips-container .card {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: none;
  overflow: hidden;
}

.services .trips-container img {
  width: 100%;
  height: 250px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.services .trips-container .card .card-body h5 {
  color: #777;
}

.services .trips-container .card .card-body p {
  padding-top: 0px !important;
}

.services .trips-container .card .card-body small span {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: bold;
}

.services .trips-container .card .card-body a {
  margin: 0rem;
  margin-top: 0 !important;
  width: fit-content;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.services .trips-container .card .card-body a:hover {
  background-color: var(--primary-color-hover);
}

.services .notFound h1 {
  text-align: center;
  opacity: 0.2;
}

/* ======== MEDIA QIURIES ============ */

@media screen and (max-width: 1200px) {
  .services .trips-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .services .trips-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
