.trips {
  padding-bottom: 150px;
  padding-top: 100px;
}

.trips .trips-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.trips .trips-container .card {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: none;
  overflow: hidden;
}

.trips .trips-container img {
  width: 100%;
  height: 300px;
  padding: 0.8rem;
  overflow: hidden;
  border-radius: 1.2rem;
  -webkit-border-radius: 1.2rem;
  -moz-border-radius: 1.2rem;
  -ms-border-radius: 1.2rem;
  -o-border-radius: 1.2rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.trips .trips-container p {
  padding-top: 20px;
}

.trips .trips-container a:nth-child(2) {
  position: relative;
  top: 26px;
}

.trips .trips-container .details-btn {
  margin: 1rem;
  margin-top: 0 !important;
  width: fit-content;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.trips .trips-container .details-btn:hover {
  background-color: var(--primary-color-hover);
}

.trips button {
  margin-top: 3rem;
  width: fit-content;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.trips button:hover {
  background-color: var(--primary-color-hover);
}

/* ======== MEDIA QIURIES ============ */

@media screen and (max-width: 1200px) {
  .trips .trips-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .trips .trips-container a:nth-child(2) {
    position: relative;
    top: 26px;
  }
}

@media screen and (max-width: 600px) {
  .trips {
    padding-top: 100px;
  }
  .trips .trips-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
