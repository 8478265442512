.login {
  margin: 100px 0;
}

.login .login-container {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.login .login-container form {
  width: 50%;
}

.login .login-container .input-box {
  width: 100%;
  margin-bottom: 20px;
}

.login .login-container .error {
  /* text-align: center; */
  color: var(--main-color) !important;
  margin-bottom: 20px;
}

.login .login-container input {
  border-bottom: 1px solid #777;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.login .login-container input:nth-child(2) {
  margin-bottom: 0 !important;
}

.login-container .details-btn {
  margin-top: 0 !important;
  margin: 10px auto;
  width: 70%;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.login-container .details-btn button {
  margin: 10px auto;
}

.login .login-container p {
  margin: 20px 0;
  text-align: start !important;
}

.login .login-container p a {
  color: var(--main-color);
}

@media screen and (max-width: 767px) {
  .login .login-container form {
    width: 100%;
  }
}
