.tripsDetails {
  margin-bottom: 150px;
}

.tripsDetails .tripsDetails-container .gallery {
  margin: 50px 0;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #151616c2;
  opacity: 0.7;
}

.tripsDetails .video-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.tripsDetails .tripsDetails-container .gallery img {
  width: 93%;
  height: 50vh;
  margin: 10px auto;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.tripsDetails .tripsDetails-container img:hover {
  opacity: 0.7;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.slick-next:before,
.slick-prev:before,
.slick-next:before,
.slick-prev::after {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: var(--secondary-color);
  display: none;
}

.slick-next {
  display: none !important;
}

.tripsDetails .tripsDetails-container .tripInfo .details {
  margin: 50px 0;
  display: flex;
  gap: 100px;
  align-items: center;
}

.tripsDetails .tripsDetails-container .tripInfo .details .price {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 25px;
}

.tripsDetails .tripsDetails-container .tripInfo .details .icon {
  background: #f2f6ff;
  color: #9597fb;
  font-size: 35px;
  padding: 10px;
}

.tripsDetails .tripsDetails-container .tripInfo .sm-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.tripsDetails .tripsDetails-container .tripInfo .details small {
  color: #777;
}

.tripsDetails .tripsDetails-container .details-btn {
  margin-top: 2rem !important;
}

.tripsDetails .tripsDetails-container .details-btn a {
  width: fit-content;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.tripsDetails .tripsDetails-container .details-btn a:hover {
  background-color: var(--primary-color-hover) !important;
  color: #fff !important;
}

@media screen and (max-width: 767px) {
  .tripsDetails .tripsDetails-container .tripInfo .details {
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
  }
  .slick-next:before,
  .slick-prev:before,
  .slick-next:before,
  .slick-prev::after {
    display: none;
  }
  .slick-next {
    display: none !important;
  }
}
