.header {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #151616c2;
  opacity: 0.7;
  z-index: 1;
}

.header .video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.header .header-container .text-info {
  z-index: 999;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.header .header-container .text-info h1 {
  color: #fff;
  font-size: 50px;
  font-weight: bold;
}

.header .header-container .text-info h5 {
  color: #fff;
  font-size: 22px;
  padding: 0.5rem 0 2rem 0;
}

.header .header-container .text-info button {
  margin: 1rem;
  margin-top: 0 !important;
  width: fit-content;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 1rem 2rem;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.header .header-container .text-info button:hover {
  background: var(--primary-color-hover);
  color: #fff;
}

/* Media Qiuires */

@media screen and (max-width: 555px) {
  .header .header-container .text-info h1 {
    padding: 10px 20px;
    font-size: 2rem;
  }

  .header .header-container .text-info p {
    font-size: 1rem;
    padding: 0 0 2rem 0;
  }

  .header .header-container .text-info button {
    padding: 0.6rem 1.1rem;
    font-size: 1rem;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }
}
