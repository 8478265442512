.navbar {
  border-radius: 13px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 90vw !important;
  height: 120px;
  z-index: 99999;
  position: fixed !important;
  right: 5%;
  margin-top: 2rem;
  background: #fff;
}

.navbar .langList {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar .firstSelect {
  display: none !important;
}

.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap !important;
}

.navbar .container .logo {
  width: 20% !important;
  height: 100% !important;
}

.navbar .container .logo img {
  max-width: 100%;
}

.navbar .container .close {
  display: none;
}

.navbar .container ul li svg {
  font-size: 20px;
  margin-right: 8px;
  text-align: center;
  margin-bottom: 4px;
}

.navbar .container .navbar-toggler:focus {
  box-shadow: none;
}

.navbar .container ul li {
  padding-left: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.navbar .container ul li a {
  color: #000;
  font-size: 18px;
  font-weight: bold;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.navbar .container ul li {
  text-align: center;
}

/* .navbar-nav .nav-link.active {
  color: var(--secondary-color);
} */

/*============== MEDIA =============*/

@media screen and (max-width: 991px) {
  .navbar .container ul li svg {
    padding-left: 2px;
    width: 100%;
    display: block;
    position: absolute;
    left: -64px;
    top: 12px;
  }
  .navbar .container ul li a {
    padding-left: 8px;
    position: relative;
    perspective: 400px;
  }

  .navbar .container ul {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    position: absolute;
    top: 58px;
    left: 0;
    opacity: 1;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    width: 100%;
    z-index: -1;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
  }
  .navbar .container ul li {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
    z-index: 99;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    width: 90% !important;
    right: 5%;
  }
}
