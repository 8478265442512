.dashboardNavbar {
  width: 100% !important;
  z-index: 99999;
  position: relative !important;
  margin-top: 0rem;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.topbar .topbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar .logo img {
  width: 7rem;
}

.topbar ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-left: 0 !important;
}

.topbar ul li {
  padding-left: 0 !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.topbar ul li a {
  color: black;
  font-size: 18px;
  font-weight: bold;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}
