.tripsControl h2 {
  margin-bottom: 30px;
}

.tripsControl label {
  display: block !important;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}

.tripsControl .content {
  margin: 50px 0;
}

.tripsControl .input-box .selection-box {
  width: 400px;
  margin-right: 50px;
}

.tripsControl .input-box .selection-box select {
  border: 1px solid #e1e0e0;
  padding-bottom: 20px;
}

.tripsControl .input-box input {
  padding: 10px;
  border: 1px solid #e1e0e0;
  width: 400px;
  margin-right: 50px;
}

.tripsControl .input-box input:nth-child(1) {
  margin-right: 50px;
}

.tripsControl .content p {
  color: var(--main-color) !important;
  border: 1px solid var(--main-color);
  background-color: #7ac24d3b;
  width: 400px;
  margin-top: 10px;
  padding: 10px;
}

.tripsControl .details-btn {
  margin-top: 30px !important;
  width: 30%;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

@media (max-width: 992px) {
  .tripsControl .content .input-box {
    flex-direction: column !important;
  }
  .tripsControl .content form .input-box {
    margin-top: 15px;
  }
  .tripsControl .content .input-box input {
    margin-right: 0 !important;
    margin-bottom: 10px;
    width: 100%;
  }
  .tripsControl .content p {
    width: 100%;
  }
  .tripsControl .content form .input-box:nth-child(5) {
    margin-top: 0 !important;
  }
  .tripsControl .input-box .selection-box {
    width: 100%;
    margin-right: 0px;
  }
}
