.contact {
  margin: 100px 0;
}

.contact h1 {
  animation: fadeInDown 2s;
  -webkit-animation: fadeInDown 2s;
}

.contact .box {
  display: flex;
  gap: 20px;
  background-color: #fff2ed;
  padding: 30px;
  width: 100%;
  height: 100%;
}

.contact .icon-box svg {
  font-size: 30px;
  color: #2da4ff;
}

.contact .phone svg {
  color: #24c193;
}

.contact .location svg {
  color: #f64873;
}

.contact .box .info-box p {
  color: #777;
}
