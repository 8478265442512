.travelWithUs {
  margin-bottom: 150px;
}

.travelWithUs .box {
  text-align: center;
}

.travelWithUs .box .icon svg {
  font-size: 70px;
  color: var(--main-color);
}

.travelWithUs .box h5 {
  margin: 20px 0;
}

.travelWithUs .box p {
  max-width: 95%;
}