.footer {
  background: #000000;
  padding: 70px;
  padding-bottom: 30px;
}

.footer h5 {
  font-size: 1.1rem;
}

.footer .others h5 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer .others h5 a {
  color: #fff !important;
  letter-spacing: 1px;
}

.footer .others h5 svg {
  font-size: 20px;
}

.footer .social svg {
  font-size: 30px;
  margin-left: 20px;
  background: #fff;
  color: #000000;
  padding: 4px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.footer .social svg:hover {
  background: var(--main-color);
}

.footer .links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}

.footer .links h2 {
  font-size: 25px;
  padding-bottom: 18px;
  font-weight: bold;
}

.footer .links h5 {
  cursor: pointer;
  padding: 5px;
}

.footer .copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  margin-top: 40px;
  border-top: 1px solid #777;
}

/* MEDIA QUIRES */

@media screen and (max-width: 1200px) {
  .footer {
    padding: 40px;
    width: 100%;
  }
  .footer .trippy {
    flex-direction: column;
    text-align: center;
  }
  .footer .social {
    margin-top: 10px;
    text-align: center;
  }
  .footer .links {
    grid-template-columns: 1fr 1fr;
    padding-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 20px;
    width: 100%;
  }
  .footer .trippy {
    text-align: center;
  }
  .footer .social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
    margin-top: 10px;
    text-align: center;
  }
  .footer .links {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding-left: 0;
  }
  .footer .copyright {
    align-items: center;
  }
}
