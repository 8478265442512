.resetPass {
  padding: 180px 0;
}

.resetPass .resetPass-container {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.resetPass .resetPass-container form {
  width: 30%;
}

.resetPass .resetPass-container .input-box {
  width: 100%;
  margin-bottom: 20px;
}

.resetPass .resetPass-container .error {
  text-align: center;
  color: var(--main-color) !important;
  margin-bottom: 20px;
}

.resetPass .resetPass-container input {
  border-bottom: 1px solid #777;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.resetPass .resetPass-container input:nth-child(2) {
  margin-bottom: 0 !important;
}

.resetPass-container .details-btn {
  margin-top: 0 !important;
  width: 70%;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  .resetPass .resetPass-container form {
    width: 100%;
  }
}
