.about {
  padding-top: 100px;
  padding-bottom: 100px;
}

.about .about-history,
.about .about-mission {
  grid-template-columns: 40% 50%;
  place-items: center;
}

.about .about-vision {
  grid-template-columns: 50% 40%;
  place-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.about .container p {
  line-height: 1.7;
  font-weight: bold;
}

.about .container h1 {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 18px;
}

.about .container img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.about .container .about-mission img {
  width: 500px;
  height: 400px;
}

@media screen and (max-width: 1024px) {
  .about .about-history,
  .about .about-mission {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10%;
  }
  .about .about-vision {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14%;
    grid-row: 1;
    margin-left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .about .about-vision img {
    margin-left: 0px;
  }
  .about .about-vision .text-box {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .about .container h1 {
    font-size: 35px;
  }
  .about .about-history,
  .about .about-mission {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10%;
    margin-bottom: 3rem;
  }
  .about .about-vision {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14%;
    grid-row: 1;
    margin-left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .about .about-vision img {
    margin-left: 0px;
  }
  .about .about-vision .text-box {
    margin-bottom: 2rem;
  }
  .about .container .img-box {
    width: 350px;
    height: 250px;
  }

  .about .container img {
    width: 100%;
    height: 100%;
  }

  .about .container .about-mission img {
    width: 350px;
    height: 250px;
  }
}
