.addedTrips .btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.addedTrips .btn-box button {
  margin-right: 10px;
  color: #fff;
  font-size: 1rem;
  background-color: var(--main-color);
  padding: 3px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
