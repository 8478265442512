.popular .popular-box {
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.popular .popular-box:nth-child(2) {
  flex-direction: row-reverse;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.popular .text-box {
  width: 90%;
  margin: 10px auto;
}

.popular .popular-container h1 {
  font-size: 40px;
  font-weight: bold;
}

.popular p {
  line-height: 1.6;
}

.popular .img-box {
  display: flex;
  gap: 20px;
}

.popular .img-box .img2 {
  margin-top: 50px;
}

.popular img {
  width: 250px;
  height: 350px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

/* media quiries */

@media screen and (max-width: 1200px) {
  .popular .popular-box {
    flex-direction: column;
    gap: 40px;
    padding-bottom: 3rem;
  }
  .popular .popular-box:nth-child(2) {
    flex-direction: column;
    gap: 50px;
    padding-top: 6rem;
    padding-bottom: 0rem;
  }
  .popular .colums {
    width: 100%;
  }
  .popular .text-box {
    width: 100%;
  }
  .popular .popular-container h1 {
    font-size: 30px;
    font-weight: bold;
  }
  .popular p {
    width: 100% !important;
  }
  .popular .img-box {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
}

@media screen and (max-width: 600px) {
  .popular .colums {
    width: 100%;
  }
  .popular .headline h1 {
    font-size: 27px;
  }
  .popular .popular-box {
    flex-direction: column;
    gap: 20px;
    padding-top: 0rem;
  }
  .popular .popular-box:nth-child(2) {
    flex-direction: column;
    gap: 20px;
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
  .popular .text-box {
    width: 100%;
  }
  .popular .popular-container h1 {
    font-size: 20px;
    font-weight: bold;
  }
  .popular p {
    width: 100%;
  }
  .popular .img-box {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .popular img {
    width: 170px;
    height: 260px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
}
