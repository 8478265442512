.signup {
  margin: 100px 0;
}

.signup .signup-container {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.signup .signup-container form {
  width: 50%;
}

.signup .signup-container .input-box {
  width: 100%;
  margin-bottom: 30px;
}

.signup .signup-container .input-box p {
  color: var(--main-color) !important;
}

.signup .signup-container input {
  border-bottom: 1px solid #777;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.signup .signup-container input:nth-child(2) {
  margin-bottom: 0 !important;
}

.signup-container .details-btn {
  margin-top: 0 !important;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: var(--main-color);
  padding: 10px 20px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

@media screen and (max-width: 767px) {
  .signup .signup-container form {
    width: 100%;
  }
}
